<template>
  <BaseList
    :list-query="signa1"
    route="signum1"
    locale-section="pages.signa1"
  />
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "Signa1List",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  data() {
    return {
      signa1: gql`
        query signa1 {
          list: signa1 {
            id
            name: signum1
            altschema
            deprecated
            shortSource
          }
        }
      `
    };
  }
};
</script>
